exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-pages-about-us-index-js": () => import("./../../../src/components/pages/about-us/index.js" /* webpackChunkName: "component---src-components-pages-about-us-index-js" */),
  "component---src-components-pages-contact-index-js": () => import("./../../../src/components/pages/contact/index.js" /* webpackChunkName: "component---src-components-pages-contact-index-js" */),
  "component---src-components-pages-maintence-index-js": () => import("./../../../src/components/pages/maintence/index.js" /* webpackChunkName: "component---src-components-pages-maintence-index-js" */),
  "component---src-components-pages-pricing-index-js": () => import("./../../../src/components/pages/pricing/index.js" /* webpackChunkName: "component---src-components-pages-pricing-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-privacy-policy-template-js": () => import("./../../../src/templates/privacyPolicyTemplate.js" /* webpackChunkName: "component---src-templates-privacy-policy-template-js" */)
}

